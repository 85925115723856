/**
 * Javascript pro veřejnou část stránek.
 *
 * @package    www-elisha/eshop.eurositex.cz
 * @subpackage .
 * @author     Reklalink <info@reklalink.cz>
 * @copyright  2020 Reklalink s.r.o.
 */

$(document).ready(
	function () {
		bsCustomFileInput.init();

		$('[data-toggle="tooltip"]').tooltip();

		// Homepage menu.
		if ($('.category').length) {
			$('.category').each(
				function () {
					var id = $(this).attr('data-target');
					$(id).on(
						'shown.bs.collapse',
						function () {
							if ($(id).attr('data-collapse-hide') === 'true') {
								$(id).collapse('hide');
							}
						}
					);
					$(id).on(
						'hide.bs.collapse',
						function () {
							$(id).attr('data-collapse-hide');
						}
					);
				}
			);

			$('.category').hover(
				function () {
					var show_all = $('#category').attr('data-show-all');
					var id       = $(this).attr('data-target');

					if (show_all === 'FALSE') {
						$(id).attr('data-collapse-hide', '');
						$(id).collapse('show');
					}
				},
				function () {
					var show_all = $('#category').attr('data-show-all');
					var id       = $(this).attr('data-target');

					if (show_all === 'FALSE') {
						$(id).attr('data-collapse-hide', 'true');
						$(id).collapse('hide');
					}
				}
			);

			if ($('.category-all').length) {
				$('.category-all').on(
					'click',
					function () {
						var show_all = $('#category').attr('data-show-all');
						if (show_all === 'FALSE') {
							$('#category').attr('data-show-all', 'TRUE');
							$('.category').each(
								function () {
									var id = $(this).attr('data-target');
									$(id).attr('data-collapse-hide', '');
									$(id).collapse('show');
								}
							);
						} else {
							$('#category').attr('data-show-all', 'FALSE');
							$('.category').each(
								function () {
									var id = $(this).attr('data-target');
									$(id).attr('data-collapse-hide', 'true');
									$(id).collapse('hide');
								}
							);
						}
					}
				);
			}//end if
		}//end if

		if ($('#items-per-page .filter').length) {
			$('#items-per-page .filter').on(
				'click',
				function (event) {
					$('#items-per-page .filter').removeClass('active');
					$(this).addClass('active');
					event.preventDefault();
				}
			);
		}

		if ($('#order .nav-item .nav-link').length) {
			$('#order .nav-item .nav-link').on(
				'click',
				function (event) {
					$('#order .nav-item .nav-link').removeClass('active');
					$(this).addClass('active');
					event.preventDefault();
				}
			);
		}

		if ($('[data-gtm-event]').length) {
			$('[data-gtm-event]').each(
				function () {
					var data = $(this).attr('data-gtm-event');
					var json = JSON.parse(data);

					window.dataLayer = window.dataLayer || [];
					dataLayer.push(json);
				}
			);
		}

		if ($('[data-matomo-event]').length && typeof _paq !== undefined) {
			$('[data-matomo-event]').each(
				function () {
					var data = $(this).attr('data-matomo-event');
					var json = JSON.parse(data);

					_paq.push(json);
				}
			);
		}

		if ($('.autoComplete').length) {
			init_autocomplete('autoCompleteH');
		}//end if

		my_validate();
	}
);

function init_autocomplete(element_id)
{
	var element           = '#' + element_id;
	var element_result_id = element_id + '_result_wrapper';
	var element_result    = '#' + element_result_id;

	new autoComplete(
		{
			data: {
				src: async function () {
					const placeholder = document.querySelector(element).dataset.placeholder;
					const query       = document.querySelector(element).value;

					const source = await fetch('/content/search/?ajax=autocomplete&q=' + query);
					const data   = await source.json();

					document.querySelector(element).setAttribute('placeholder', placeholder);

					return data;
				},
				key: [
					'name'
				],
				cache: false
			},
			sort: function (a, b) {
				if (a.value.score < b.value.score) {
					return 1;
				}

				if (a.value.score > b.value.score) {
					return -1;
				}


				if (a.match < b.match) {
					return -1;
				}

				if (a.match > b.match) {
					return 1;
				}

				return 0;
			},
			selector: element,
			threshold: 1,
			debounce: 400,
			searchEngine: function (query, record, config) {
				return record;
			},
			highlight: false,
			maxResults: 10,
			resultsList: {
				render: true,
				container: function (source) {
					source.setAttribute('id', element_result_id);
					source.setAttribute('class', 'autoComplete_result_wrapper d-none');
				},
				destination: document.querySelector(element),
				position: 'afterend',
				element: 'div'
			},
			resultItem: {
				content: function (data, source) {
					source.innerHTML = '<a href="' + data.value.link + '"><span class="item_image"><img src="' + data.value.image + '" alt="' + data.match + '"></span><span class="item_name">' + data.match + '</span><span class="item_price">' + data.value.price + '</span></a>';
				},
				element: 'span'
			}
		}
	);

	// Když se spustí autoComplete event (začneme vyhledávat).
	document.querySelector(element).addEventListener(
		'autoComplete',
		function (event) {
			// Pokud máme k dispozici nějaké výsledky z vyhledávání.
			if (event.detail.matches > 0) {
				// Odstraníme zneviditelnění boxu s výsledky.
				document.querySelector(element_result).classList.remove('d-none');
				// Zaregistrujeme si spuštění funkce autocomplete_click kdekoli v okně prohlížeče.
				document.addEventListener('click', autocomplete_click);
			} else {
				// Pokud nejsou žádný výsledky nic nezobrazíme.
				document.querySelector(element_result).classList.add('d-none');
				// Případný handler kliku dáme pryč aby se nehromadily.
				document.removeEventListener('click', autocomplete_click);
			}
		}
	);

	// Když uživatel klikne do vyhledávacího políčka.
	document.querySelector(element).addEventListener(
		'click',
		function (event) {
			// ID vyhledávacího políčka.
			var id = event.target.id;
			// Počet prvků v divu s výsledky = počet výsledků.
			var len = document.querySelector('#' + id + '_result_wrapper').children.length;

			// Pokud jsou k dispozici nějaké výsledky.
			if (len > 0) {
				// Zobrazíme výsledkové pole.
				document.querySelector('#' + id + '_result_wrapper').classList.remove('d-none');

				// Smažeme handler kliku kdekoliv a zaregistrujeme nový aby se výsledky daly opět zavřít klikem bokem.
				document.removeEventListener('click', autocomplete_click);
				document.addEventListener('click', autocomplete_click);
			} else {
				// Pokud nejsou výsledky zajistíme aby nebyly zobrazeny.
				document.querySelector('#' + id + '_result_wrapper').classList.add('d-none');
				// Odstraníme handler pro zavírání výsledků.
				document.removeEventListener('click', autocomplete_click);
			}
		}
	);

}

function autocomplete_click(event)
{
	// Pokud klikneme někam kromě vyhledávacího pole nebo pole s výsledky.
	if (event.target.classList.contains('autoComplete_result_wrapper') === false
		&& event.target.classList.contains('autoComplete') === false
	) {
		// Pole s výsledky zavřeme.
		document.querySelector('.autoComplete_result_wrapper').classList.add('d-none');
		// Odstraníme handler pro zavírání výsledků.
		document.removeEventListener('click', autocomplete_click)
	}
}


function my_validate()
{
	var ajax_form_before_lang_arr = {
		cs: 'Formulář se odesílá, prosím počkejte.',
		en: 'The form is being submitted, please wait.',
		ru: 'Форма отправляется, пожалуйста, подождите.'
	};

	var ajax_form_error_lang_arr = {
		cs: 'Bohužel formulář nelze odeslat, prosím kontaktujte nás jinou cestou. Děkujeme za pochopení.',
		en: 'Unfortunately the form cannot be sent, please contact us in another way Thank you for your understanding.',
		ru: 'К сожалению, форма не может быть отправлена, пожалуйста, свяжитесь с нами другим способом.\
				Спасибо за ваше понимание.'
	};

	var form = $('.validate');
	form.each(
		function () {
			var self = $(this);
			$(this).validate(
				{
					ignore: [],
					lang: $('html').attr('lang'),
					highlight: function (element) {
						$(element).closest('div').addClass('has-error');
					},
					unhighlight: function (element) {
						if ($(element).hasClass('error-as-tooltip')) {
							$(element).tooltip('dispose');
							$(element).attr('data-title', false);
							$(element).data('title', false);
						}
						$(element).closest('div').removeClass('has-error');
					},
					errorElement: 'span',
					errorClass: 'form-text',
					errorPlacement: function (error, element) {
						if (element.hasClass('error-as-tooltip')) {
							var last_error = $(element).attr('data-title');
							if (last_error !== error.text()) {
								$(element).tooltip('dispose')
									.attr('data-title', error.text())
									.data('title', error.text())
									.tooltip(
										{
											trigger: 'manual',
											template: '<div class="tooltip has-error" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
										}
									)
									.tooltip('show');
							}
						} else if (element.parent('.input-group').length) {
							$('<div>', {class: 'has-error'}).append(error).insertAfter(element.parent());
						} else if (element.hasClass('select2')) {
							error.insertAfter(element.parent());
						} else if (element.parent('.custom-checkbox').length) {
							error.insertAfter(element.parent('.custom-checkbox').find('.custom-control-label'));
						} else if (element.parent('.custom-file').length) {
							error.insertAfter(element.parent('.custom-file').find('.custom-file-label'));
						} else {
							error.insertAfter(element);
						}//end if
					},
					submitHandler: function (form) {
						var ajax = $(form).attr('data-ajax');

						if (typeof ajax !== typeof undefined && ajax !== false) {
							var form_response = $(form).find('.response');
							var form_data     = new FormData();

							// Projdem všechna data formuláře.
							var self_zero_length = self[0].length;
							for (i = 0; i < self_zero_length; i++) {
								var item  = self[0][i];
								var $item = $(item);
								if ($item.attr('type') === 'hidden' || $item.attr('type') === 'button' || $item.attr('type') === 'submit') {
									continue
								} else if ($item.attr('type') === 'file') {
									// Vložíme obrázky.
									var files_length = item.files.length;
									for (j = 0; j < files_length; j++) {
										form_data.append($item.attr('name'), item.files[j]);
									}
								} else if ($item.attr('type') === 'checkbox') {
									// Emulace chování PHP.
									if ($item.is(':checked') === true) {
										form_data.append($item.attr('name'), 'on');
									}
								} else {
									form_data.append($item.attr('name'), $item.val());
								}
							}//end for

							$.ajax(
								{
									url: '?ajax=' + ajax,
									type: 'POST',
									data: form_data,
									processData: false,
									contentType: false,
									beforeSend: function () {
										$(form).find('button[type="submit"]').attr('disabled','disabled');
										form_response.empty();
										form_response.append('<div class="alert alert-info" role="alert"><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i> ' + ajax_form_before_lang_arr[$('html').attr('lang')] + '</div>');
									},
									success: function (response) {
										form_response.empty();
										form_response.append(response);

										var matomo_event_category = self.attr('data-matomo-event-category');
										var matomo_event_action   = self.attr('data-matomo-event-action');
										var matomo_event_name     = self.attr('data-matomo-event-name');

										if (typeof _paq !== 'undefined'
											&& typeof matomo_event_category !== 'undefined'
											&& typeof matomo_event_action !== 'undefined'
											&& typeof matomo_event_name !== 'undefined'
											&& matomo_event_category !== ''
											&& matomo_event_action !== ''
											&& matomo_event_name !== ''
										) {
											_paq.push(['trackEvent', matomo_event_category, matomo_event_action, matomo_event_name]);
										}
									},
									error: function () {
										form_response.empty();
										form_response.append('<div class="alert alert-danger" role="alert"><i class="fa fa-ban fa-3x fa-fw"></i> ' + ajax_form_error_lang_arr[$('html').attr('lang')] + '</div>');
									}
								}
							);
							return false;
						} else {
							return true;
						}//end if
					}
				}
			);
		}
	);
}//end my_validate()
